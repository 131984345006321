import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import axios from 'axios';
import Layout from 'components/layout';
//import Box from 'components/box';
import Title from 'components/title';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { AppContext } from '../store/createContext';
import createPersistedState from 'use-persisted-state';
import _ from 'lodash';
import {
  Grommet,
  Box,
  RadioButtonGroup,
  Keyboard,
  Heading,
  TextInput,
  RoutedButton,
  List,
  Text,
  Grid,
  Button,
  Clock,
  Layer,
  Select
} from 'grommet';
import { grommet } from 'grommet/themes';
import { useAsyncRun, useAsyncTask } from 'react-hooks-async';
import Spinner from '../components/utils/spinner';
import { StatusGood, StatusCritical } from 'grommet-icons';
import moment from 'moment-timezone';
import { Close} from 'grommet-icons';

const pushCheckpointRecord = async ({ signal }, checkpointRecordPush) => {
  //console.log('RACE ID', checkpointRecord);

  const response = await fetch(
    `${process.env.GATSBY_DRUPAL_API_URL}/api/event/${
      checkpointRecordPush.event
    }/register_athlete_activity/${checkpointRecordPush.dorsal}/checkpoint/${
      checkpointRecordPush.checkpoint
    }/${checkpointRecordPush.timestamp}`,
    {
      signal,
      method: 'POST',
    }
  );
  const data = await response.json();
  return data;
};

const RecordHero = checkpointRecord => {
  // console.log(
  //   'Record Hero ',
  //   checkpointRecord.body.timestamp,
  //   checkpointRecord.body
  // );

  const { start, started, error, result, pending } = useAsyncTask(
    pushCheckpointRecord
  );
  const { eventRecords, setEventRecords } = useContext(AppContext);
  const [status, setStatus] = useState(checkpointRecord.body.status);
  if (checkpointRecord.body.status == 'inited' && !started) {
    start(checkpointRecord.body);
    //checkpointRecord.method({})
    //console.log('Started Sync ', checkpointRecord.body);
  }

  useEffect(() => {
    // console.log('EFFECT -> Fetch ', checkpointRecord, status);

    if (checkpointRecord.body.status != 'synched') {
      const updatedRecodList = eventRecords.map((record, index) => {
        if (
          record.dorsal == checkpointRecord.body.dorsal &&
          record.timestamp == checkpointRecord.body.timestamp
        ) {
          record.status =
            !pending && !error && !_.isNull(result) ? 'synched' : 'pending';
          setStatus(record.status);
          // console.log(
          //   'fetch status',
          //   !pending,
          //   !error,
          //   !_.isNull(result),
          //   ' ----',
          //   record,
          //   checkpointRecord
          // );

          return record;
        } else {
          return record;
        }
      });
      setEventRecords(updatedRecodList);
    } else {
     // console.log('RecordHero ssynched', result, started, error, checkpointRecord);
    }
  }, [pending]);

  return (
    <Box>
      {started && <Spinner color="plain" size="xlarge" />}
      {status == 'synched' && <StatusGood color="green" size="large" />}
      {status == 'pending' && !started && (
        <StatusCritical
          color="red"
          size="large"
          onClick={() => start(checkpointRecord.body)}
        />
      )}
    </Box>
  );
};

function Form() {
  const { currentEvent, eventRecords, setEventRecords } = useContext(
    AppContext
  );
  const [dorsal, setDorsal] = useState('-');
  const [checkpoint, setCheckpoint] = useState([]);
  const [currentCheckpointRecord, setCurrentCheckpointRecord] = useState({});
  const [open, setOpen] = useState(undefined);

  const dorsalInp = useRef(null);

  useEffect(() => {
    console.log(
      'Effect send Record',
      currentCheckpointRecord,
      eventRecords,
      currentEvent
    );

    if (!_.isEmpty(currentCheckpointRecord)) {
      if (!_.isEmpty(eventRecords)) {
        setEventRecords([...eventRecords, currentCheckpointRecord]);
      } else {
        setEventRecords([currentCheckpointRecord]);
      }
      console.log(
        'send Record',
        currentCheckpointRecord,
        eventRecords,
        currentEvent
      );
      setDorsal('');
    }
    return () => {
      // Clean up the CurrentCheckpointRecord
      //setCurrentCheckpointRecord({})
      dorsalInp.current.focus();
    };
  }, [currentCheckpointRecord]);

  const registreCheckpointRecord = e => {

    if (!_.isEmpty(checkpoint) && checkpoint != '-') {
      var currentTime = Date.now()
      console.log('registreCheckpointRecord', currentTime, currentEvent);


      setCurrentCheckpointRecord({
        event: currentEvent.nid,
        dorsal: dorsal,
        checkpoint: checkpoint.value,
        timestamp: currentTime,
        status: 'inited',
      });
      onClose();
    } else {
      onOpen();
    }
  };

  const columnOptions = currentEvent.checkpoints
    ? currentEvent.checkpoints.map(col => ({
        label: String(col.title).replace(/\[.*?\]/g, ''),
        value: col.nid,
      }))
    : [];
  const ref = React.useRef();
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(undefined);
  return (
    <Layout>
      {_.isEmpty(currentEvent) && (
        <Heading margin="none">Seleccione un Evento</Heading>
      )}
      {!_.isEmpty(currentEvent) && (
        <Grid
          rows={['xxxsmall', 'small']}
          columns={['2/8', '4/8']}
          areas={[
            ['header', 'header'],
            ['input', 'log'],
          ]}
          gap="small"
        >
           <Box gridArea="header" background="brand">
            <Title as="h2" size="large">
              {String(currentEvent.name)}
            </Title>
            <Clock alignSelf="end" size="xlarge" type="digital" />
          </Box>
          <Box gridArea="input" background="light-2">
            <Box background="brand">
              <Select
                 labelKey="label"
                options={columnOptions}
                value={checkpoint}
                valueLabel={checkpoint.label}
                onChange={ ({option})  => setCheckpoint(option)}
                placeholder="Punto de Control"

              />
            </Box>
            <Keyboard onEnter={e => registreCheckpointRecord(e)}>
              <TextInput
                ref={dorsalInp}
                placeholder="#"
                type="number"
                size="xxlarge"
                value={dorsal}
                onChange={event => setDorsal(event.target.value)}
              />
            </Keyboard>
            <Button
              label="Registrar"
              onClick={e => registreCheckpointRecord(e)}
            />
          </Box>
          <Box gridArea="log" background="plain" height="large" overflow="auto">
            {!_.isEmpty(eventRecords) && (
              <div>

                <List
                  data={_.orderBy(eventRecords, ['timestamp'], ['desc'])}
                  primaryKey={item => (
                    <Text size="large" weight="bold">
                      {item.dorsal}
                    </Text>
                  )}
                  secondaryKey={item => (
                    <div>
                      <Text size="small" color="dark-4">
                        {moment(parseInt(item.timestamp)).format('HH:mm:ss')}
                      </Text>
                      <br />

                    </div>
                  )}
                  action={item => (
                    <RecordHero
                      key={'RH' + item.timestamp}
                      hoverIndicator
                      body={item}
                      method={setCurrentCheckpointRecord}
                    />
                  )}
                />{' '}

              </div>
            )}
             <Button
                 icon={<Close />}
                  size="xxsmall"
                  color="dark-4"
                  placeholder="Borrar registros"
                  onClick={() => setEventRecords([])}
                  alignSelf="end"
                />
          </Box>
          {open && (
            <Layer
              position="right"
              target={ref.current}
              modal
              onClickOutside={onClose}
              onEsc={onClose}
            >
              <Box pad="medium" gap="small" width="medium">
                <Heading level="5">Selecciona un Checkpoint</Heading>
                <Button label="Close" onClick={onClose} />
              </Box>
            </Layer>
          )}
        </Grid>
      )}
      <div style={{ height: '50vh' }} />
    </Layout>
  );
}

export default Form;
